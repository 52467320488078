@import './reset';
@import './vars';

// RESET

.invisible {
  visibility: hidden;
}

* {
  font-family: $fontPrimary;
}

html {
  font-size: 12px;
}

body {
  background-color: $colorBaseline;
}

h1 {
  display: block;
  font-size: 1.5em;
  margin-top: 0.67em;
  margin-bottom: 0.67em;
  margin-left: 0;
  margin-right: 0;
  font-weight: bold;
}

h2 {
  display: block;
  font-size: 1.33em;
  margin-top: 0.83em;
  margin-bottom: 0.83em;
  margin-left: 0;
  margin-right: 0;
  font-weight: bold;
}

h3 {
  display: block;
  font-size: 1.17em;
  margin-top: 1em;
  margin-bottom: 1em;
  margin-left: 0;
  margin-right: 0;
  font-weight: bold;
}

h4 {
  display: block;
  font-size: 0.94em;
  margin-top: 1.33em;
  margin-bottom: 1.33em;
  margin-left: 0;
  margin-right: 0;
  font-weight: bold;
}

a {
  text-decoration: none;
  font-size: $fontSizeBase;
  font-weight: 700;
  color: $fontColorDark;
  display: inline-block;
}

i {
  font-size: 16px;
}

.mat-flat-button,
.mat-stroked-button {
  i {
    &::before {
      transform: translateY(-0.16em);
    }
  }
}

// deletes "powered by google" logo from places autocomplete

.pac-container::after {
  display: none;
}

// Override Material

::ng-deep .mat-form-field {
  font-size: 12px;
}

.snackbar {
  padding: 0px !important;
}

.mat-card {
  -webkit-box-shadow: 0 4px 2px -2px rgba(0, 0, 0, 0.16), 0 2px 2px 0 rgba(0, 0, 0, 0.08), 0 2px 6px 0 rgba(0, 0, 0, 0.08) !important;
  -moz-box-shadow: 0 4px 2px -2px rgba(0, 0, 0, 0.16), 0 2px 2px 0 rgba(0, 0, 0, 0.08), 0 2px 6px 0 rgba(0, 0, 0, 0.08) !important;
  box-shadow: 0 4px 2px -2px rgba(0, 0, 0, 0.16), 0 2px 2px 0 rgba(0, 0, 0, 0.08), 0 2px 6px 0 rgba(0, 0, 0, 0.08) !important;

  .mat-card-content {
    font-size: $fontSizeBase !important;
  }
}

.mat-chip {
  font-size: $fontSizeBase !important;

  &.mat-chip-disabled {
    opacity: 1 !important;
  }
}

.mat-button-toggle-label-content {
  line-height: 3em !important;
}
