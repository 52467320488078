@import '_palettes';

// FONTS

$fontPrimary: 'Helvetica Neue', 'Roboto', 'sans serif';
// $fontSecondary: 'Helvetica Neue', sans-serif;
$fontColorLight: $light-primary-text;
$fontColorDark: $dark-primary-text;
$fontColorDescription: rgba(0, 0, 0, 0.48);
$fontColorEmpty: rgba(0, 0, 0, 0.16);
$fontSizeBase: 12px;

// COLORS

$colorPrimary: map-get($themeColorPrimary, 500);
$colorSecondary: map-get($themeColorSecondary, 500); // note: Material calls these 'accent' when used as inline in components
// $colorError: map-get($themeColorWarn, 500);
$colorDisabled: rgba(0, 0, 0, 0.24);
$colorEmpty: rgba(0, 0, 0, 0.08);
$colorBorder: map-get($themeColorPrimary, 600);
$colorBorderEmpty: rgba(0, 0, 0, 0.32);
$colorDivider: rgba(0, 0, 0, 0.12);

$colorBaseline: map-get($themeColorPrimary, 50); // The baseline color appears behind scrollable content.
$colorSurface: map-get($themeColorPrimary, 100); // Surface colors are baseline colors for components, such as cards, sheets, and menus.
$colorLight: #fff;
$colorError: hsl(4, 90%, 58%);
$colorWarn: hsl(48, 89%, 50%);
$colorSuccess: hsl(145, 63%, 49%);
