/* prettier-ignore */

// TEXT

$dark-primary-text: rgba(black, 0.87);
$light-primary-text: white;

// PALETTES

// BASE = 600
$themeColorPrimary: (
  50: #fefeff,
  100: #e8f1f3,
  200: #c6dce2,
  300: #a4c7d1,
  400: #82b2c0,
  500: #609daf,
  600: #4a8292,
  700: #396470,
  800: #28464f,
  900: #16282d,
  A100: #e8f1f3,
  A200: #c6dce2,
  A400: #82b2c0,
  A700: #396470,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $dark-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $light-primary-text,
    A200: $light-primary-text,
    A400: $light-primary-text,
    A700: $light-primary-text,
  ),
);

// BASE = 600
$themeColorSecondary: (
  50: #fef8f4,
  100: #fce5d4,
  200: #f8c9a4,
  300: #f5ac75,
  400: #f19045,
  500: #ee7416,
  600: #c35c0e,
  700: #93460b,
  800: #642f07,
  900: #341904,
  A100: #fce5d4,
  A200: #ee7416,
  A400: #ee7416,
  A700: #93460b,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $dark-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $light-primary-text,
    A200: $light-primary-text,
    A400: $light-primary-text,
    A700: $light-primary-text,
  ),
);

// BASE = 600
$themeColorWarn: (
  50: #ffebee,
  100: #ffcdd2,
  200: #ef9a9a,
  300: #e57373,
  400: #ef5350,
  500: #f44336,
  600: #e53935,
  700: #d32f2f,
  800: #c62828,
  900: #b71c1c,
  A100: #ff8a80,
  A200: #ff5252,
  A400: #ff1744,
  A700: #d50000,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $dark-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $light-primary-text,
    A400: $light-primary-text,
    A700: $light-primary-text,
  ),
);
